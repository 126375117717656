import React from "react";
import AboutBackground from "../assets/about-bg.png";
import AboutBackgroundImage from "../assets/about-bg-image.png";
import AboutBackgroundImage2 from "../assets/about-bg-image-2.png";
import { BsFillPlayCircleFill } from "react-icons/bs";

const About = () => {
  return (
    <div className="about-section-container">
      <div className="about-background-image-container">
        <img src={AboutBackground} alt="" />
      </div>
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
        <img src={AboutBackgroundImage2} alt="" />
      </div>
      <div className="about-section-text-container">
        <p className="primary-subheading">About</p>
        <h1 className="primary-heading">
            Think Skin, Think Cooleet!
        </h1>
        <p className="primary-text">
        With Cooleet, identifying potential skin diseases is as simple as snapping a photo or uploading an image.
        </p>
        <p className="primary-text">
        Cooleet evaluates your photos using cutting-edge AI technology to provide accurate, timely forecasts. Discover the skin health of the future—rapidly, dependable, and at your fingertips.
        </p>
        <div className="about-buttons-container">
          <button className="watch-video-button">
            <BsFillPlayCircleFill /> Watch Video
          </button>
        </div>
      </div>
    </div>
  );
};

export default About;
