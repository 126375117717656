import React from "react";

const Feature = () => {
  const features = [
    {
      title: "AI-Powered Skin Disease Detection",
      text: "Upload or take a picture to receive fast, precise analysis and forecasts from AI.",
    },
    {
      title: "Real-time UV Index Monitoring",
      text: "Receive individualized skin protection advice and be informed about UV levels with real-time updates.",
    },
    {
      title: "Find Nearby Specialists",
      text: "Quickly find and connect with the best dermatology specialists near you.",
    },
  ];
  return (
    <div className="feature-section-wrapper">
      <div className="feature-section-top">
        <p className="primary-subheading">Feature</p>
        <h1 className="primary-heading centered">Oh no, there's something wrong with my skin</h1>
        <p className="primary-text">
            Discover the cutting-edge features of Cooleet
        </p>
      </div>
      <div className="feature-section-bottom">
        {features.map((data) => (
          <div className="feature-section-info" key={data.title}>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Feature;
