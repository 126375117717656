import React from "react";
import GooglePlay from "../assets/google-play-logo.png";

const Download = () => {
    return (
        <div className="feature-section-wrapper">
          <div className="feature-section-top">
            <p className="primary-subheading">Download</p>
            <h1 className="primary-heading centered">Get started today</h1>
          </div>
          <div className="download-section-bottom">
            <img className="centered" src={GooglePlay} alt="" />
          </div>
        </div>
    );
};

export default Download;
