import React from "react";
import Navbar from "./Navbar";
import BannerBackground from "../assets/home-banner-bg.png";
import BannerImage from "../assets/home-banner-img.png";
import { FiArrowRight } from "react-icons/fi";

const Home = () => {
    return (
        <div className="home-container">
            <Navbar/>
            <div className="home-banner-container">
                <div className="home-bannerImage-container">
                <img src={BannerBackground} alt="" />
                </div>
                <div className="home-text-section">
                <h1 className="primary-heading">
                    AI-Powered Skin Disease Detection
                </h1>
                <p className="primary-text">
                    Our advanced AI technology analyzes and provides fast, accurate predictions. Say goodbye to long waits for consultations.
                </p>
                <button className="secondary-button">
                    Get it now <FiArrowRight />{" "}
                </button>
                </div>
                <div className="home-image-section">
                    <img src={BannerImage} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Home;
