import React from "react";
import Logo from "../assets/logo-cooleet.svg";
import { SiLinkedin } from "react-icons/si";
import { BsYoutube } from "react-icons/bs";

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-section-one">
        <div className="footer-logo-container">
          <img src={Logo} alt="" />
        </div>
        <div className="footer-icons">
          <SiLinkedin />
          <BsYoutube />
        </div>
      </div>
      <div className="footer-section-two">
        <div className="footer-section-columns">
          <span>Download</span>
          <a target="_blank" href="https://grow.google/intl/id_id/bangkit/?tab=machine-learning"><span>Bangkit Academy</span></a>
        </div>
        <div className="footer-section-columns">
          <span>help@cooleet.uk</span>
          <span>27 Tovey Cres,<br/>Leicester LE2 9DF,<br/>United Kingdom</span>
        </div>
        <div className="footer-section-columns">
          <span>Terms & Conditions</span>
          <span>Privacy Policy</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;